<div class="tube-legend" *ngIf="metricId === 'capacityConstraint'">
    <ul>
        <li *ngFor="let item of capacityCon" class="{{item.class}}">
            <span> {{item.label}} </span>
        </li>
    </ul>
</div>
<div class="tube-legend" *ngIf="metricId === 'pacing'">
    <ul>
<!--        <li class="metric-name">{{selectedMetric.name}}: </li>-->
        <li class="pacing-lowest-color">
            <span>&nbsp; < -20 &nbsp;&nbsp; </span>
        </li>
        <li class="pacing-low-color">
            <span>&nbsp; -20 to -10 &nbsp;&nbsp; </span>
        </li>
        <li class="pacing-med-low-color">
            <span>&nbsp; -10 to 0 &nbsp;&nbsp; </span>
        </li>
        <li class="pacing-med-color">
            <span>&nbsp; 0 to 10 &nbsp;&nbsp; </span>
        </li>
        <li class="pacing-med-high-color">
            <span>&nbsp; 10 to 20 &nbsp;&nbsp; </span>
        </li>
        <li class="pacing-high-color">
            <span>&nbsp; > 20 &nbsp;&nbsp; </span>
        </li>
        <li class="unavailable-color">
            <span>&nbsp; Unavailable</span>
        </li>
    </ul>
</div>
<div class="tube-legend" *ngIf="metricId === 'revenueOpportunity'">
    <ul>
<!--        <li class="metric-name">{{selectedMetric.name}}: </li>-->
        <li class="rev-opp-high-color">
            <span>&nbsp; > 1500 &nbsp;&nbsp; </span>
        </li>
        <li class="rev-opp-med-high-color">
            <span>&nbsp; 750 to 1500 &nbsp;&nbsp; </span>
        </li>
        <li class="rev-opp-med-color">
            <span>&nbsp; 250 to 750 &nbsp;&nbsp; </span>
        </li>
        <li class="rev-opp-med-low-color">
            <span>&nbsp; 0 to 250 &nbsp;&nbsp; </span>
        </li>
        <li class="rev-opp-low-color">
            <span>&nbsp; < 0 &nbsp;&nbsp; </span>
        </li>
        <li class="unavailable-color">
            <span>&nbsp; Unavailable</span>
        </li>
    </ul>
</div>
<div class="tube-legend" *ngIf="metricId === 'walkUpOverride'">
    <ul>
        <li class="walk-up-override-color">
            <span>&nbsp; Override</span>
        </li>
        <li class="unavailable-color">
            <span>&nbsp; Unavailable</span>
        </li>
    </ul>
</div>
